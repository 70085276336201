import React, { useState } from 'react';
import {
  ActionIcon,
  Alert,
  Button,
  Stack,
  Text,
  Textarea,
  Title,
  Modal,
  Divider,
} from '@mantine/core';
import { IconCheck, IconPencil, IconAlertTriangle } from '@tabler/icons-react';

const ORG_BACKGROUND_INFO_PLACEHOLDER = `Example:
- Our company is called Salv AI. It is headquarter in San Francisco, CA.
- We specialize in building automated recruiting solutions
- Our corporate phone number is  877-527-7454
- If the candidate asks about benefits, say we offer a comprehensive benefits package including medical, dental, and vision. A recruiter can go more in-depth on a follow-up call`;

const WARNING_YELLOW = '#ffd13b';

const BackgroundInfoStep = ({
  scriptInfo,
  orgBackgroundInfo,
  companyInfoEdits,
  setCompanyInfoEdits,
  areUnsavedCompanyInfoDiffs,
}) => {
  const [confirmEditCompanyModalOpen, setConfirmEditCompanyModalOpen] =
    useState(false);
  const [editngCompanyInfo, setEditingCompanyInfo] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <Stack style={{ minHeight: '300px', height: '40vh' }}>
        <Title order={5}>Job-Specific Background Info and Instructions</Title>
        <Text fz='sm' c='dimmed'>
          Background knowledge the AI can use to provide context or answer
          questions about the job.
          <br />
          This is not directly part of the script.
        </Text>
        <Textarea
          className='fullHeightTextarea'
          placeholder={`-This candidate had applied to our ad on Indeed.
-The jobsite is at 123 1st St, San Francisco, CA 94105. It is just off highway 1 near the McDonald's.
-This is a 6 month contract with opportunity to convert to perm after that.`}
          {...scriptInfo.getInputProps('backgroundInfo')}
        />
      </Stack>
      <Modal.Root
        opened={confirmEditCompanyModalOpen}
        onClose={() => {
          setConfirmEditCompanyModalOpen(false);
        }}
        size='lg'
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title style={{ fontWeight: 'bold' }}>
              Company Edit Warning Confirmation
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Divider />
          <Modal.Body>
            <br />
            <Alert
              color='yellow'
              icon={<IconAlertTriangle size={16} />}
              style={{ padding: '16px', backgroundColor: WARNING_YELLOW }}
            >
              <div style={{ fontSize: '16px' }}>
                <b>Warning</b>: Changes to company info will affect all scripts
                and sequences
              </div>
            </Alert>
            <br />
            <div>
              Modifications to the company background information will be
              applied to <b>all jobs, scripts, and campaign sequences</b> in
              your organization.
            </div>
            <br />
            <br />
            <Divider />
            <div
              style={{
                display: 'flex',
                marginTop: '16px',
                gap: '20px',
              }}
            >
              <Button
                variant='outline'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setConfirmEditCompanyModalOpen(false);
                  setEditingCompanyInfo(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant='filled'
                color={WARNING_YELLOW}
                style={{ marginRight: 'auto', '--button-color': 'black' }}
                onClick={() => {
                  setConfirmEditCompanyModalOpen(false);
                  setEditModalOpen(true);
                }}
              >
                <IconAlertTriangle size={16} style={{ marginRight: '8px' }} />
                Acknowledge and Edit
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root
        opened={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setEditingCompanyInfo(false);
        }}
        size='lg'
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title style={{ fontWeight: 'bold' }}>
              Edit Company Background Info
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Divider />
          <Modal.Body>
            <br />
            <Alert
              color='yellow'
              icon={<IconAlertTriangle size={16} />}
              style={{ padding: '16px', backgroundColor: WARNING_YELLOW }}
            >
              <div style={{ fontSize: '16px' }}>
                <b>Warning</b>: Changes to company info will affect all scripts
                and sequences
              </div>
            </Alert>
            <br />
            <div>
              Modifications to the company background information will be
              applied to <b>all jobs, scripts, and campaign sequences</b> in
              your organization.
            </div>
            <br />
            <Textarea
              className='fullHeightTextarea'
              placeholder={ORG_BACKGROUND_INFO_PLACEHOLDER}
              value={companyInfoEdits}
              onChange={(e) => setCompanyInfoEdits(e.target.value)}
              style={{ marginTop: '16px', height: '200px' }}
            />
            <br />
            <Divider />
            <div
              style={{
                display: 'flex',
                marginTop: '16px',
                gap: '20px',
              }}
            >
              <Button
                variant='outline'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setEditModalOpen(false);
                  setEditingCompanyInfo(false);
                  setCompanyInfoEdits(
                    orgBackgroundInfo.values.callOrgBackgroundInfo
                  );
                }}
              >
                Discard
              </Button>
              <Button
                variant='light'
                style={{
                  marginRight: 'auto',
                  backgroundColor: WARNING_YELLOW,
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  '--button-color': 'black',
                }}
                onClick={() => {
                  setEditModalOpen(false);
                  setEditingCompanyInfo(false);
                  orgBackgroundInfo.setValues({
                    callOrgBackgroundInfo: companyInfoEdits,
                  });
                }}
              >
                Save For All Jobs
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <Stack style={{ height: '40vh' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '14px',
          }}
        >
          <Title order={5}>Company Info, Policies, and Instructions</Title>
          <ActionIcon
            variant={editngCompanyInfo ? 'filled' : 'subtle'}
            onClick={() => {
              if (!editngCompanyInfo) {
                setConfirmEditCompanyModalOpen(true);
              } else {
                setEditingCompanyInfo(false);
                if (
                  orgBackgroundInfo.values.callOrgBackgroundInfo !==
                  companyInfoEdits
                ) {
                  orgBackgroundInfo.setValues({
                    callOrgBackgroundInfo: companyInfoEdits,
                  });
                }
              }
            }}
            style={{
              marginLeft: editngCompanyInfo ? '14px' : '8px',
            }}
          >
            {editngCompanyInfo ? (
              <IconCheck size={16} />
            ) : (
              <IconPencil size={16} />
            )}
          </ActionIcon>
          {editngCompanyInfo && areUnsavedCompanyInfoDiffs && (
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <Button
                variant='transparent'
                size='xs'
                onClick={() => {
                  setEditingCompanyInfo(false);
                  setCompanyInfoEdits(
                    orgBackgroundInfo.values.callOrgBackgroundInfo
                  );
                }}
              >
                Discard Edits
              </Button>
            </div>
          )}
        </div>

        {editngCompanyInfo && (
          <div className='callout'>
            Modifications to the company background information will be applied
            to all jobs, scripts, and campaign sequences in your organization.
          </div>
        )}

        <Text fz='sm' c='dimmed'>
          Background knowledge the AI can use to provide context or answer
          questions about the company.
          <br />
          This is not directly part of the script.
        </Text>
        {editngCompanyInfo ? (
          <>
            <Textarea
              className='fullHeightTextarea'
              placeholder={ORG_BACKGROUND_INFO_PLACEHOLDER}
              value={companyInfoEdits}
              onChange={(e) => setCompanyInfoEdits(e.target.value)}
            />
          </>
        ) : (
          <div
            style={{
              color: orgBackgroundInfo.values.callOrgBackgroundInfo
                ? ''
                : 'gray',
              whiteSpace: 'pre-line',
              border: '1px solid #DCDCDC',
              borderRadius: '4px',
              fontSize: '14px',
              padding: '8px',
              paddingLeft: '14px',
              opacity: orgBackgroundInfo.values.callOrgBackgroundInfo
                ? 0.95
                : 0.6,
              backgroundColor: orgBackgroundInfo.values.callOrgBackgroundInfo
                ? '#F8F8F8'
                : '#FAFAFA',
              maxHeight: '400px',
              overflowY: 'scroll',
              height: '100%',
            }}
          >
            {orgBackgroundInfo.values.callOrgBackgroundInfo
              ? orgBackgroundInfo.values.callOrgBackgroundInfo
              : ORG_BACKGROUND_INFO_PLACEHOLDER}
          </div>
        )}
      </Stack>
    </>
  );
};

export default BackgroundInfoStep;
