// ImportanceBadgeSelector.tsx
import React from 'react';

import BadgeSelector, { BadgeSelectorOption } from './BadgeSelector';
// Import RequirementImportance enum
import { RequirementImportance } from '../types';

// Define IMPORTANCE_LABELS
export const IMPORTANCE_LABELS: Record<RequirementImportance, string> = {
  [RequirementImportance.Major]: 'Major',
  [RequirementImportance.Normal]: 'Normal',
  [RequirementImportance.Minor]: 'Minor',
  [RequirementImportance.Info]: 'Info',
};

// Define IMPORTANCE_COLORS
export const IMPORTANCE_COLORS: Record<RequirementImportance, string> = {
  [RequirementImportance.Major]: 'var(--mantine-color-red-9)',
  [RequirementImportance.Normal]: 'var(--salv-dark-6)',
  [RequirementImportance.Minor]: 'var(--salv-dark-4)',
  [RequirementImportance.Info]: 'var(--mantine-color-blue-4)',
};

// Define IMPORTANCE_OPTIONS
const IMPORTANCE_OPTIONS: BadgeSelectorOption[] = Object.entries(
  IMPORTANCE_LABELS
).map(([value, label]) => ({
  value,
  label,
  color: IMPORTANCE_COLORS[value as RequirementImportance],
}));

// Define the props specific to ImportanceBadgeSelector
interface ImportanceBadgeSelectorProps {
  value: RequirementImportance;
  onChange: (value: RequirementImportance) => void;
}

// ImportanceBadgeSelector Component
const ImportanceBadgeSelector: React.FC<ImportanceBadgeSelectorProps> = ({
  value,
  onChange,
}) => {
  // Convert RequirementImportance to string for the generic BadgeSelector
  const stringValue = value as unknown as string;

  // Handle change by converting string back to RequirementImportance
  const handleChange = (newValue: string) => {
    onChange(newValue as RequirementImportance);
  };

  return (
    <BadgeSelector
      value={stringValue}
      onChange={handleChange}
      options={IMPORTANCE_OPTIONS}
    />
  );
};

export default ImportanceBadgeSelector;
