// ImportanceBadgeSelector.tsx
import React from 'react';

import BadgeSelector, { BadgeSelectorOption } from './BadgeSelector';
// Import RequirementImportance enum
import { QuestionType } from '../types';

// Define IMPORTANCE_LABELS
export const QUESTION_TYPE_LABELS: Record<QuestionType, string> = {
  [QuestionType.Normal]: 'Normal',
  [QuestionType.AutoGenerated]: 'Auto Generated',
  [QuestionType.GradeOnly]: 'Grade Only',
};

// Define IMPORTANCE_COLORS
export const QUESTION_TYPE_COLORS: Record<QuestionType, string> = {
  [QuestionType.Normal]: 'var(--salv-dark-6)',
  [QuestionType.AutoGenerated]: 'var(--mantine-color-blue-4)',
  [QuestionType.GradeOnly]: 'var(--mantine-color-blue-8)',
};

// Define IMPORTANCE_OPTIONS
const QUESTION_TYPE_OPTIONS: BadgeSelectorOption[] = Object.entries(
  QUESTION_TYPE_LABELS
).map(([value, label]) => ({
  value,
  label,
  color: QUESTION_TYPE_COLORS[value as QuestionType],
}));

// Define the props specific to ImportanceBadgeSelector
interface QuestionTypeBadgeSelectorProps {
  value: QuestionType;
  onChange: (value: QuestionType) => void;
}

// ImportanceBadgeSelector Component
const QuestionTypeBadgeSelector: React.FC<QuestionTypeBadgeSelectorProps> = ({
  value,
  onChange,
}) => {
  // Convert RequirementImportance to string for the generic BadgeSelector
  const stringValue = value as unknown as string;

  // Handle change by converting string back to RequirementImportance
  const handleChange = (newValue: string) => {
    onChange(newValue as QuestionType);
  };

  return (
    <BadgeSelector
      value={stringValue}
      onChange={handleChange}
      options={QUESTION_TYPE_OPTIONS}
    />
  );
};

export default QuestionTypeBadgeSelector;
