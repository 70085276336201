// atoms.ts
import { atom } from 'jotai';
import { selectAtom, atomFamily } from 'jotai/utils';

import { ScriptInfo } from './types';

export const scriptInfoAtom = atom<ScriptInfo | null>(null);
export const webCallAtom = selectAtom(
  scriptInfoAtom,
  (scriptInfo) => !!scriptInfo?.webCall
);
export const scheduleFollowUpAtom = selectAtom(
  scriptInfoAtom,
  (scriptInfo) => !!scriptInfo?.scheduleFollowUp
);
export const transcriptionCacheAtom = atom({});

export type CandidateCampaignFeedback =
  | 'thumbs_up'
  | 'thumbs_down'
  | 'no_feedback';

type CandidateCampaignInfo = {
  feedback?: CandidateCampaignFeedback;
  // You can add more fields here as you need
};

export const candidateCampaignInfoDictAtom = atom<
  Record<string, CandidateCampaignInfo>
>({});

export const candidateUpdateTimestampsAtom = atom<{ [key: string]: number }>(
  {}
); // use this to prevent updates due to race conditions

export const campaignInfoAtomForCandidate = atomFamily(
  (campaignCandidateId: string) =>
    atom(
      // Getter: returns that candidate's object from the global store
      (get) => {
        const allCandidateInfo = get(candidateCampaignInfoDictAtom);
        return allCandidateInfo[campaignCandidateId] || {};
      },
      // Setter: merges updates into that candidate's object with timestamp tracking
      (
        get,
        set,
        updatedFields: Partial<CandidateCampaignInfo> & { _fetchedAt?: number }
      ) => {
        const prevData = get(candidateCampaignInfoDictAtom);
        const prevTimestamps = get(candidateUpdateTimestampsAtom);

        const currentTime = Date.now();
        const lastUpdateTime = prevTimestamps[campaignCandidateId] || 0;

        if (
          updatedFields._fetchedAt &&
          updatedFields._fetchedAt < lastUpdateTime
        ) {
          // If the fetched data is older than the last update, ignore it
          alert('ignore!');
          return;
        }

        // Update the timestamp only if it's a user-triggered update
        const isUserUpdate = !updatedFields._fetchedAt;

        set(candidateCampaignInfoDictAtom, {
          ...prevData,
          [campaignCandidateId]: {
            ...prevData[campaignCandidateId],
            ...updatedFields,
          },
        });

        if (isUserUpdate) {
          set(candidateUpdateTimestampsAtom, {
            ...prevTimestamps,
            [campaignCandidateId]: currentTime,
          });
        }
      }
    )
);
