import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import axios from 'api/axiosConfig';
import env from 'env';
import { useParams } from 'react-router-dom';
import { RetellWebClient } from 'retell-client-js-sdk';
import { Loader } from '@mantine/core';
import { throttle } from 'lodash';
import { CloseButton } from '@mantine/core';

import { decodeData } from './utils';
import RetellControlsArea from './components/RetellControlsArea';
import RetellWebCallHandler from './components/RetellWebCallHandler';
import './WebCallPage.css';
import { useIsMobile } from './components/useIsMobile';

// Define the type for the decoded data
interface DecodedData {
  campaignId: string;
  candidateName: string;
  voiceName: string;
  defaultId: string;
  candidateTimezone?: string;
}

const WebCallPage = () => {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [callId, setCallId] = useState<string | null>(null);
  const [decodedData, setDecodedData] = useState<DecodedData | null>(null);

  const { encodedData, encodedId } = useParams<{
    encodedData?: string;
    encodedId?: string;
  }>();

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const retellWebClientRef = useRef<RetellWebClient | null>(null);
  const [startClicked, setStartClicked] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [candidateName, setCandidateName] = useState('');
  const [expired, setExipred] = useState(false);
  const [validated, setValidated] = useState<boolean>(!encodedId);
  const [voiceName, setVoiceName] = useState<string>('');

  const [interviewerMessages, setInterviewerMessages] = useState<string[]>([]);

  const [showMicTestModal, setShowMicTestModal] = useState(false);
  const micTestStreamRef = useRef<MediaStream | null>(null);
  const micTestAnalyserRef = useRef<AnalyserNode | null>(null);

  const [messagesVisible, setMessagesVisible] = useState(true);

  const isMobile = useIsMobile();

  const THROTTLE_S = useMemo(() => {
    return isMobile ? 100 : 50; // request animation frames messes up clicking on mobile
  }, [isMobile]);

  const getAccessTokenSetupCall = async () => {
    // Gets access token for either test call or real call depending on url parameters
    if (encodedId) {
      const payload = {
        webCallEncodedId: encodedId,
      };
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/candidate_web_call`,
          payload
        );
        setCallId(response.data.data.call_id);
        setAccessToken(response.data.data.access_token);
        setCandidateName(response.data.data.candidate_name);
        setVoiceName(response.data.data.voice_name || '');
      } catch (error) {
        console.error('Error initiating web call:', error);
      }
      return;
    }
    // Else, do test
    if (!decodedData) {
      console.error('Decoded data is not available');
      return;
    }

    const payload: DecodedData = {
      campaignId: decodedData.campaignId,
      candidateName: decodedData.candidateName,
      voiceName: decodedData.voiceName,
      defaultId: decodedData.defaultId,
      candidateTimezone: decodedData.candidateTimezone || '',
    };

    try {
      console.log('setting voice name to', decodedData?.voiceName || '');
      setVoiceName(decodedData?.voiceName || '');
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/test_web_call`,
        payload
      );
      setCallId(response.data.data.call_id);
      setAccessToken(response.data.data.access_token);
    } catch (error) {
      console.error('Error initiating web call:', error);
    }
  };

  // This is a slighlty hacky version of having a global store to prevent uneeded re-renders when the state changes
  // A better design is a global state store and having micLevels subscribe
  const micBarsUpdateRef = useRef<(level: number) => void>();
  const onMicLevelChange = (newMicLevel: number) => {
    // Instead of setting state here, we call the callback we got from MicBars
    if (micBarsUpdateRef.current) {
      micBarsUpdateRef.current(newMicLevel);
    }
  };

  const updateMicLevelsRef = useRef<number>();

  const throttledSetMicLevel = useMemo(
    () => throttle((volume: number) => onMicLevelChange(volume), THROTTLE_S), // Throttle to 50ms
    [THROTTLE_S]
  );

  useEffect(() => {
    return () => throttledSetMicLevel.cancel();
  }, [throttledSetMicLevel]);

  const startMicTest = useCallback(async () => {
    try {
      if (!micTestStreamRef.current) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        micTestStreamRef.current = stream;
      }

      const audioContext = new AudioContext({ sampleRate: 48000 });

      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      const microphone = audioContext.createMediaStreamSource(
        micTestStreamRef.current
      );
      microphone.connect(analyser);
      micTestAnalyserRef.current = analyser;
      const updateMicLevels = () => {
        if (!micTestAnalyserRef.current) return;

        const dataArray = new Uint8Array(
          micTestAnalyserRef.current.frequencyBinCount
        );
        micTestAnalyserRef.current.getByteFrequencyData(dataArray);

        const sensitivityBoost = 2.0;
        const rms = Math.sqrt(
          dataArray.reduce((sum, value) => sum + value ** 2, 0) /
            dataArray.length
        );

        const normalizedVolume = Math.min((rms / 255) * sensitivityBoost, 1);

        throttledSetMicLevel(normalizedVolume); // Use throttled function

        updateMicLevelsRef.current = requestAnimationFrame(updateMicLevels);
      };

      updateMicLevelsRef.current = requestAnimationFrame(updateMicLevels);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  }, [throttledSetMicLevel]);

  const stopMicTest = useCallback(() => {
    if (updateMicLevelsRef.current) {
      cancelAnimationFrame(updateMicLevelsRef.current);
      updateMicLevelsRef.current = undefined;
    }

    if (micTestStreamRef.current) {
      micTestStreamRef.current.getTracks().forEach((track) => track.stop());
      micTestStreamRef.current = null;
    }

    if (micTestAnalyserRef.current) {
      micTestAnalyserRef.current.disconnect();
      micTestAnalyserRef.current = null;
    }

    onMicLevelChange(0);
  }, []);

  const handleOpenMicTest = () => {
    setShowMicTestModal(true);
    startMicTest();
  };

  const handleCloseMicTest = () => {
    setShowMicTestModal(false);
    stopMicTest();
  };

  useEffect(() => {
    if (showMicTestModal) {
      startMicTest();
    } else {
      stopMicTest();
    }
  }, [showMicTestModal, startMicTest, stopMicTest]);

  useEffect(() => {
    const fetchDataFromEncodedId = async () => {
      const payload = {
        webCallEncodedId: encodedId,
      };
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/validate_encoded_id`,
          payload
        );
        // setCallId(response.data.data.call_id);
        // setAccessToken(response.data.data.access_token);
        setExipred(response.data.data.expired);
        setCandidateName(response.data.data.candidate_name);
        setValidated(true);
      } catch (error) {
        console.error('Error initiating web call:', error);
      }
    };
    if (encodedId) {
      fetchDataFromEncodedId();
    }
  }, [encodedId]);

  const isCallReady = useMemo(() => {
    if (encodedId && !expired && validated) {
      return true;
    }

    return (
      !!decodedData?.campaignId && !!decodedData?.candidateName && validated
    );
  }, [decodedData, encodedId, expired, validated]);

  useEffect(() => {
    if (encodedData) {
      try {
        const decoded: DecodedData = decodeData(encodedData);
        console.log('Decoded Data:', decoded);
        setDecodedData(decoded);
        setCandidateName(decoded.candidateName); // redundant but just one place for this
      } catch (error) {
        console.error('Error decoding data:', error);
      }
    }
  }, [encodedData]);

  const [hasMicrophoneAccess, setHasMicrophoneAccess] = useState<
    undefined | boolean
  >(undefined);

  useEffect(() => {
    // Add a delay of 500ms before requesting permissions
    const timer = setTimeout(() => {
      // Check if getUserMedia is supported
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {
            console.log('Microphone access granted.');
            setHasMicrophoneAccess(true);
            // Do something with the stream, like setting it in state
          })
          .catch((err) => {
            console.error(
              'Microphone access denied or an error occurred:',
              err
            );
            setHasMicrophoneAccess(false);
          });
      } else {
        console.error('getUserMedia is not supported by your browser.');
      }
    }, 50);

    // Clean up the timeout
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run only on mount

  const messagesContainerRef = useRef<HTMLDivElement>(null); // Ref for the messages container

  const isUserAtBottom = (buffer: number) => {
    console.log('checking if at bottom');
    const container = messagesContainerRef.current;
    if (!container) return false;
    // console.log('container.scrollHeight', container.scrollHeight);
    // console.log('container.scrollTop', container.scrollTop);
    // console.log('container.clientHeight', container.clientHeight);
    // console.log(
    //   'container.scrollHeight - container.scrollTop',
    //   container.scrollHeight - container.scrollTop
    // );
    return (
      container.scrollHeight - container.scrollTop - buffer <=
      container.clientHeight
    );
  };

  // Scroll to the bottom when new messages are added
  useEffect(() => {
    if (interviewerMessages.length === 0) {
      return;
    }
    const lastMsg = interviewerMessages[interviewerMessages.length - 1];
    const numWords = lastMsg.split(' ').filter((m) => m).length;
    const buffer = numWords === 1 ? 80 : 50; // more buffer if new message to account for message block space
    if (isUserAtBottom(buffer) && messagesContainerRef.current) {
      messagesContainerRef.current.style.overflowY = 'hidden';
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
      requestAnimationFrame(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.style.overflowY = 'auto';
        }
      });
    }
  }, [interviewerMessages]); // Triggered whenever interviewerMessages changes

  return (
    <div className='web-call-outer-container'>
      <div className='web-call-container'>
        <div className='web-call-inner-container'>
          {!validated && (
            <div className='expired-message'>
              <Loader type='dots' color='white' />
            </div>
          )}
          {!expired && validated && (
            <div className='call-content-wrapper'>
              <div className='retell-handler-wrapper'>
                <RetellWebCallHandler
                  accessToken={accessToken}
                  callId={callId}
                  mediaRecorderRef={mediaRecorderRef}
                  retellWebClientRef={retellWebClientRef}
                  setInterviewerMessages={setInterviewerMessages}
                  startClicked={startClicked}
                  callEnded={callEnded}
                  setCallEnded={setCallEnded}
                  candidateName={candidateName}
                  voiceName={voiceName || ''}
                  showMicTestModal={showMicTestModal}
                  getMicLevelUpdates={(callback) => {
                    micBarsUpdateRef.current = callback;
                  }}
                  handleCloseMicTest={handleCloseMicTest}
                  hasMicrophoneAccess={hasMicrophoneAccess}
                />
              </div>
              {!isMobile && (
                <div
                  className={`info-panel ${messagesVisible ? '' : 'panel-hidden'}`}
                >
                  <div className='white-box'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingLeft: '16px',
                        paddingRight: '12px',
                      }}
                    >
                      <h4 className='info-header'>Interviewer Messages</h4>
                      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                        <CloseButton
                          onClick={() => setMessagesVisible(false)}
                        />
                      </div>
                    </div>
                    {messagesVisible && (
                      <div
                        className='messages-container'
                        ref={messagesContainerRef}
                      >
                        {interviewerMessages.length === 0 ? (
                          <>
                            <div className='message'>
                              Interview questions will appear in this chat
                              window.
                            </div>
                            <div className='message'>
                              The chat window can be minimized or re-opened at
                              any time.
                            </div>
                          </>
                        ) : (
                          <>
                            {interviewerMessages.map((msg) => (
                              <div className='message'>{msg}</div>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {expired && (
            <div className='expired-message'>Interview Link Expired</div>
          )}
        </div>
        <div className='web-controls-area'>
          <RetellControlsArea
            getAccessTokenSetupCall={getAccessTokenSetupCall}
            isCallReady={isCallReady}
            mediaRecorderRef={mediaRecorderRef}
            retellWebClientRef={retellWebClientRef}
            startClicked={startClicked}
            setStartClicked={setStartClicked}
            setMessagesVisible={setMessagesVisible}
            callEnded={callEnded}
            handleOpenMicTest={handleOpenMicTest}
            hasMicrophoneAccess={hasMicrophoneAccess}
          />
        </div>
      </div>
    </div>
  );
};

export default WebCallPage;
