import React, { createRef, useEffect, useState, useCallback } from 'react';
import {
  Anchor,
  Button,
  Divider,
  FileInput,
  Flex,
  ScrollArea,
  Table,
  Text,
  Modal,
  LoadingOverlay,
} from '@mantine/core';
import { startCampaign } from 'pages/scripts/actions';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconExclamationCircle,
  IconPlayerPlay,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import env from 'env';

import axios from '../../api/axiosConfig';

import './AddContactsModal.css';

interface PreviewData {
  valid_rows: Array<any>;
  invalid_phone_numbers: string[];
  invalid_emails: string[];
  duplicate_phone_numbers: string[];
  missing_fields_count: number;
  already_uploaded_in_campaign: { [key: string]: any }[];
  already_uploaded_in_other_campaigns: { [key: string]: any }[];
  other_errors: string[];
  missing_data_rows: { [key: string]: any }[];
}

const AddContactsModal = ({
  campaignModalOpen,
  setCampaignModalOpen,
  campaignId,
  isCampaignActive,
  setIsCampaignActive,
  handleContactsRefresh,
  isWebCall,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = createRef<HTMLButtonElement>();

  const [contactsSuccess, setContactsSuccess] = useState(false);
  const [numContactsUploaded, setNumContactsUploaded] = useState(undefined);
  const [campaignSuccess, setCampaignSuccess] = useState(false);

  const [previewData, setPreviewData] = useState<PreviewData>({
    valid_rows: [],
    invalid_phone_numbers: [],
    invalid_emails: [],
    duplicate_phone_numbers: [],
    missing_fields_count: 0,
    already_uploaded_in_campaign: [],
    already_uploaded_in_other_campaigns: [],
    other_errors: [],
    missing_data_rows: [],
  });
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [modalContent, setModalContent] = useState<{ [key: string]: any }[]>(
    []
  );
  const [modalOpen, setModalOpen] = useState(false);

  const handleFileSelect = (event) => {
    const file = event;
    console.log('file', file);
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      const isCSV = extension === 'csv' || file.type === 'text/csv';
      if (isCSV) {
        console.log('file', file);
        setSelectedFile(file);
      } else {
        notifications.show({
          title: 'Invalid File',
          message: 'Only CSV files are allowed.',
          color: 'red',
        });
      }
    }
  };

  const orgId = localStorage.getItem('orgId') || '';

  const handlePreview = useCallback(() => {
    setLoadingPreview(true);
    const formData = new FormData();
    console.log('selectedFile', selectedFile);
    if (selectedFile) {
      console.log('selectedFile', selectedFile);
      formData.append('file', selectedFile);
    }
    console.log('file', selectedFile);
    formData.append('campaign_id', campaignId);
    formData.append('accept_email', isWebCall);
    formData.append('org_id', orgId);
    axios
      .post('/add_contacts_preview', formData)
      .then((resp) => {
        setPreviewData(resp.data);
      })
      .catch((e) => {
        // NOTE: this handling was not tested in detail and might not make sense
        const errorDetail = e.response?.data?.detail || 'An error occurred';
        notifications.show({
          title: 'An error occurred',
          message: e instanceof Error ? e.message : '',
          color: 'red',
        });
        if (errorDetail.includes('Missing required column')) {
          setPreviewData((prevData) => ({
            ...prevData,
            other_errors: [errorDetail],
          }));
        } else if (
          errorDetail.includes('Could not find the following required keys')
        ) {
          setPreviewData((prevData) => ({
            ...prevData,
            missing_fields_count: 1,
            other_errors: [errorDetail],
          }));
        } else {
          setPreviewData((prevData) => ({
            ...prevData,
            other_errors: [errorDetail],
          }));
        }
      })
      .finally(() => setLoadingPreview(false));
  }, [selectedFile, campaignId, isWebCall, orgId]);

  useEffect(() => {
    if (selectedFile) {
      handlePreview();
    }
  }, [selectedFile, handlePreview]);

  const handleCancel = () => {
    setCampaignModalOpen(false);
    setSelectedFile(null);
    setPreviewData({
      valid_rows: [],
      invalid_phone_numbers: [],
      duplicate_phone_numbers: [],
      invalid_emails: [],
      missing_fields_count: 0,
      already_uploaded_in_campaign: [],
      already_uploaded_in_other_campaigns: [],
      other_errors: [],
      missing_data_rows: [],
    });
  };

  const handleActivateCampaign = () => {
    startCampaign(campaignId)
      .then(() => {
        setIsCampaignActive(true);
        setCampaignSuccess(true);
        handleContactsRefresh();
      })
      .catch((err) => {
        console.error('error setting campaign active', err);
      });
  };

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileDrop = (e) => {
    preventDefaults(e);

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileSelect(files[0]);
    }
  };

  const handleDownloadExample = () => {
    if (isWebCall) {
      window.location.href =
        'https://salvstorageaccount.blob.core.windows.net/public/example_email_list.csv';
    } else {
      window.location.href =
        'https://salvstorageaccount.blob.core.windows.net/public/example_call_list.csv';
    }
  };

  const renderGreenCheck = (size = 48) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='green'
      className='bi bi-check-circle-fill'
      viewBox='0 0 16 16'
    >
      <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.93 10.58l-2.47-2.47-.708.708 3.18 3.18 6.364-6.364-.708-.708L6.93 10.58z' />
    </svg>
  );

  const handleSubmit = () => {
    if (!selectedFile) {
      return;
    }

    setLoadingSubmit(true);

    axios
      .post(`${env.REACT_APP_SERVER_URL}/add_contacts`, {
        campaignId: campaignId,
        validRows: previewData.valid_rows,
      })
      .then((resp) => {
        setContactsSuccess(true);
        handleContactsRefresh();
        console.log('Resp', resp);
        if (resp.data.num_contacts) {
          setNumContactsUploaded(resp.data.num_contacts);
        }
      })
      .catch((e) => {
        notifications.show({
          title: 'Failed to upload contacts',
          message: e.message,
          color: 'red',
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const renderFileInput = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingOverlay
          visible={loadingPreview}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <div style={{ width: '360px' }}>
          <div
            onDragOver={preventDefaults}
            onDrop={handleFileDrop}
            className='contacts-file-input'
          >
            <FileInput
              placeholder='Upload CSV'
              label='Upload candidates csv file'
              required
              value={selectedFile}
              ref={fileInputRef}
              error={!selectedFile}
              onChange={handleFileSelect}
            />
            <Anchor
              size='sm'
              onClick={handleDownloadExample}
              style={{ position: 'relative', top: '6px' }}
            >
              Download Example CSV
            </Anchor>
          </div>
          <br />
        </div>
      </div>
    );
  };

  const renderContactsSuccess = () => (
    <Flex
      justify='center'
      align='center'
      direction='column'
      gap='md'
      style={{ minHeight: '200px' }}
    >
      <h3
        style={{
          textAlign: 'center',
          marginTop: '0px',
          top: '-20px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {numContactsUploaded
          ? `${numContactsUploaded} Contact${numContactsUploaded === 1 ? '' : 's'} Uploaded`
          : 'Contacts Uploaded'}
        {!isCampaignActive && renderGreenCheck(24)}
      </h3>

      {isCampaignActive ? (
        renderGreenCheck()
      ) : (
        <Button
          size='md'
          leftSection={<IconPlayerPlay size={20} />}
          onClick={handleActivateCampaign}
        >
          Activate Sequence
        </Button>
      )}
    </Flex>
  );

  const renderSequenceStarted = () => (
    <Flex
      justify='center'
      align='center'
      direction='column'
      gap='md'
      style={{ minHeight: '340px' }}
    >
      <div style={{ position: 'absolute' }}>
        <Player
          autoplay
          loop
          src='/confetti.json'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '0px',
          top: '-10px',
          position: 'relative',
        }}
      >
        Sequence Started Successfully 🚀
      </h1>
      {renderGreenCheck()}
    </Flex>
  );

  const renderPreviewTable = () => {
    return (
      <div>
        <Text size='lg' fw={600} mb='sm' style={{ textAlign: 'center' }}>
          Preview Contacts ({previewData.valid_rows?.length} rows)
        </Text>
        {buttonDisabled && (
          <Text size='sm' mb='sm' style={{ textAlign: 'center', color: 'red' }}>
            All errors must be resovled before submitting contacts.
          </Text>
        )}

        <ScrollArea
          h={400}
          style={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}
        >
          <Table striped highlightOnHover>
            <thead>
              <tr>
                {Object.keys(previewData.valid_rows[0]).map((key) => (
                  <th
                    key={key}
                    style={{ textAlign: 'left', paddingLeft: '18px' }}
                  >
                    {key.replace(/_/g, ' ').toUpperCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {previewData.valid_rows.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ textAlign: 'left', paddingLeft: '18px' }}
                    >
                      {String(value) || '-'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollArea>
      </div>
    );
  };

  const buttonDisabled =
    previewData.invalid_phone_numbers?.length > 0 ||
    previewData.duplicate_phone_numbers?.length > 0 ||
    previewData.missing_fields_count > 0 ||
    previewData.invalid_emails?.length > 0 ||
    previewData.already_uploaded_in_campaign?.length > 0;

  const renderErrorMessages = () => {
    const {
      valid_rows,
      invalid_phone_numbers,
      duplicate_phone_numbers,
      already_uploaded_in_campaign,
      already_uploaded_in_other_campaigns,
      invalid_emails,
      other_errors,
      missing_data_rows,
    } = previewData;

    const messageStyles = {
      success: {
        backgroundColor: '#d4edda',
        color: '#155724',
      },
      error: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
      },
      warning: {
        backgroundColor: '#fff3cd',
        color: '#856404',
      },
      common: {
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    };

    const renderIcon = (type) => {
      switch (type) {
        case 'success':
          return (
            <IconCircleCheck
              size={16}
              color='#155724'
              style={{ marginRight: '8px' }}
            />
          );
        case 'error':
          return (
            <IconExclamationCircle
              size={16}
              color='#721c24'
              style={{ marginRight: '8px' }}
            />
          );
        case 'warning':
          return (
            <IconAlertTriangle
              size={16}
              color='#856404'
              style={{ marginRight: '8px' }}
            />
          );
        default:
          return null;
      }
    };

    const handleViewClick = (content: { [key: string]: any }[]) => {
      console.log('view content to handle view click', content);
      setModalContent(content);
      setModalOpen(true);
    };

    type ValidationMessage = {
      type: string;
      condition: boolean;
      text: string;
      viewContent?: { [key: string]: any }[];
    };

    const numDupAcrossCampaigns = Array.from(
      new Map(
        already_uploaded_in_other_campaigns.map((item) => [item.contact, item])
      ).values()
    )?.length;

    const messages: ValidationMessage[] = [
      {
        type: 'success',
        condition: valid_rows?.length > 0,
        text: `${valid_rows?.length} valid contact${valid_rows?.length === 1 ? '' : 's'} extracted`,
      },
      {
        type: 'error',
        condition: invalid_phone_numbers?.length > 0,
        text: `${invalid_phone_numbers?.length} invalid phone number${invalid_phone_numbers?.length === 1 ? '' : 's'} detected`,
        viewContent: invalid_phone_numbers.map((n): { [key: string]: any } => ({
          'Phone Number': n,
        })),
      },
      {
        type: 'error',
        condition: invalid_emails?.length > 0,
        text: `${invalid_emails?.length} invalid email${invalid_emails?.length === 1 ? '' : 's'} detected`,
        viewContent: invalid_emails.map((email): { [key: string]: any } => ({
          Email: email,
        })),
      },
      {
        type: 'error',
        condition: duplicate_phone_numbers?.length > 0,
        text: `${duplicate_phone_numbers?.length} duplicate phone number${duplicate_phone_numbers?.length === 1 ? '' : 's'} detected`,
        viewContent: duplicate_phone_numbers.map(
          (n): { [key: string]: any } => ({
            'Phone Number': n,
          })
        ),
      },
      {
        type: 'error',
        condition: already_uploaded_in_campaign?.length > 0,
        text: `${already_uploaded_in_campaign?.length} Contact${already_uploaded_in_campaign?.length > 1 ? 's' : ''} already uploaded in this campaign`,
        viewContent: already_uploaded_in_campaign,
      },
      {
        type: 'warning',
        condition: already_uploaded_in_other_campaigns?.length > 0,
        text: `${numDupAcrossCampaigns} Contact${numDupAcrossCampaigns > 1 ? 's' : ''} found in other campaigns`,
        viewContent: already_uploaded_in_other_campaigns,
      },
      {
        type: 'error',
        condition: missing_data_rows?.length > 0,
        text: 'Some rows missing required information',
        viewContent: missing_data_rows,
      },
      {
        type: 'error',
        condition: other_errors?.length > 0,
        text: other_errors?.join('/n'),
      },
    ];

    if (messages.filter((m) => m.condition).length === 0) {
      return <div style={{ marginBottom: '6px' }}></div>;
    } else {
      console.log('messages', messages);
    }

    return (
      <div style={{ padding: '10px', borderRadius: '8px' }}>
        {messages.map(
          ({ type, condition, text, viewContent }) =>
            condition && (
              <div
                key={text}
                style={{ ...messageStyles[type], ...messageStyles.common }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {renderIcon(type)}
                  <Text size='sm' style={{ textAlign: 'left' }}>
                    {text}
                  </Text>
                </div>
                {viewContent && (
                  <Button
                    size='xs'
                    variant='subtle'
                    color={
                      type === 'error' || type === 'warning'
                        ? messageStyles[type].color
                        : ''
                    }
                    onClick={() => handleViewClick(viewContent)}
                  >
                    View
                  </Button>
                )}
              </div>
            )
        )}
        <Modal
          opened={modalOpen}
          onClose={() => setModalOpen(false)}
          title='Error Details'
          size='lg'
        >
          <Divider my='sm' />
          <div style={{ maxHeight: 800, overflow: 'scroll' }}>
            {modalContent && modalContent.length > 0 ? (
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {Object.keys(modalContent[0]).map((key) => (
                      <Table.Th
                        key={key}
                        style={{ textAlign: 'left', paddingLeft: '18px' }}
                      >
                        {key.replace(/_/g, ' ').toUpperCase()}
                      </Table.Th>
                    ))}
                  </Table.Tr>
                </Table.Thead>
                <tbody>
                  {modalContent.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, colIndex) => (
                        <td
                          key={colIndex}
                          style={{ textAlign: 'left', paddingLeft: '18px' }}
                        >
                          {String(value) || '-'}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}
          </div>
        </Modal>
      </div>
    );
  };

  const renderContent = () => {
    if (campaignSuccess) {
      return renderSequenceStarted();
    } else if (contactsSuccess) {
      return renderContactsSuccess();
    } else {
      return (
        <>
          {renderErrorMessages()}
          {!previewData.valid_rows?.length
            ? renderFileInput()
            : renderPreviewTable()}
        </>
      );
    }
  };

  return (
    <Modal
      opened={campaignModalOpen}
      onClose={handleCancel}
      title='Add Contacts'
      size='lg'
    >
      <Divider style={{ marginBottom: '10px' }} />

      {renderContent()}

      {previewData.valid_rows?.length > 0 && !contactsSuccess && (
        <Flex justify='flex-end' gap='md' mt='md'>
          <Button variant='outline' color='blue' onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            loading={loadingSubmit}
            color='blue'
            disabled={buttonDisabled}
            title={buttonDisabled ? 'You must correct all red errors' : ''}
          >
            Submit
          </Button>
        </Flex>
      )}
    </Modal>
  );
};

export default AddContactsModal;
