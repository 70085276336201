import React, { useState, useEffect, useRef } from 'react';
import './AudioPlayer.css';
import axios from 'api/axiosConfig';
import { Loader } from '@mantine/core';
import env from 'env';

import PhoneCallRecordingPlayer from './PhoneCallRecordingPlayerV2';

const VideoPlayer = ({ callId, seekTimestamp, setFetchTries }) => {
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const videoRef = React.useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (seekTimestamp !== null && videoRef.current) {
      videoRef.current.currentTime = seekTimestamp;
      videoRef.current.play();
    }
  }, [seekTimestamp]);

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef<boolean>(true); // To prevent state updates after unmount

  useEffect(() => {
    // Cleanup flag to prevent state updates if the component unmounts
    isMountedRef.current = true;

    const checkVideoAvailability = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/check_web_recording/${callId}`
        );

        if (response.status === 200 && response.data.available) {
          if (isMountedRef.current) {
            // Introduce a delay before setting the videoUrl
            // Without this delay, there is a bug with ResizeObserver + the moving drawer + the <video> element
            timeoutIdRef.current = setTimeout(() => {
              setIsVideoAvailable(true);
              setVideoUrl(response.data.video_url);
              setIsLoading(false);
            }, 175);
          }
          // Stop polling if it was running
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
          }
        } else {
          throw new Error('Video not available');
        }
      } catch (error) {
        console.log('Video not available yet. Retrying...');
        if (isMountedRef.current) {
          setFetchTries((prevTries) => prevTries + 1);
        }

        // Start polling if not already started
        if (!pollingIntervalRef.current) {
          startPolling();
        }
      }
    };

    const startPolling = () => {
      pollingIntervalRef.current = setInterval(checkVideoAvailability, 3000);
    };

    // Perform the initial check immediately
    checkVideoAvailability();

    // Cleanup function to clear intervals and timeouts
    return () => {
      isMountedRef.current = false;
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [callId, setFetchTries]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '60%',
            flexDirection: 'column',
            borderRadius: '16px',
            border: '1px solid var(--salv-dark-2)',
            aspectRatio: 1.5,
            // height: '300px',
            opacity: '.7',
          }}
        >
          <h4 style={{ marginBottom: '4px' }}>Loading Video</h4>
          <Loader type='dots' size='sm' />
        </div>
      )}
      {isVideoAvailable && (
        <video
          controls
          ref={videoRef}
          style={{
            borderRadius: '16px',
            width: '60%',
            position: 'relative',
            border: '4px solid var(--salv-dark-1)',
          }}
        >
          <source src={videoUrl} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const WebCallRecordingPlayer = ({ callId, seekTimestamp, testCall }) => {
  const [fetchTries, setFetchTries] = useState(0);
  console.log('fetchTries', fetchTries);
  if (!testCall && fetchTries > 2) {
    return (
      <PhoneCallRecordingPlayer
        callId={callId}
        seekTimestamp={seekTimestamp}
        webAudio={true}
      />
    );
  }
  return (
    <div style={{ display: 'flex', fontSize: '14px' }}>
      {!!callId && (
        <VideoPlayer
          callId={callId}
          seekTimestamp={seekTimestamp}
          setFetchTries={setFetchTries}
        />
      )}
    </div>
  );
};

export default WebCallRecordingPlayer;
