import React, { useRef } from 'react';
import { Box, Badge, Menu, MantineColor } from '@mantine/core';

// Define a generic type for the options
export interface BadgeSelectorOption {
  value: string;
  label: string;
  color: MantineColor;
}

// Define the props for BadgeSelector
interface BadgeSelectorProps {
  value: string;
  onChange: (value: string) => void;
  options: BadgeSelectorOption[];
}

// Refactored BadgeSelector Component
const BadgeSelector: React.FC<BadgeSelectorProps> = ({
  value,
  onChange,
  options,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Find the currently selected option to display its label and color
  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  return (
    <Box ref={containerRef}>
      <Menu position='bottom-start'>
        <Menu.Target>
          <Badge
            variant='outline'
            color={selectedOption.color}
            style={{ fontWeight: 600, cursor: 'pointer' }}
          >
            {selectedOption.label}
          </Badge>
        </Menu.Target>
        <Menu.Dropdown style={{ padding: '8px' }}>
          {options.map((option, index) => (
            <Menu.Item
              key={option.value}
              onClick={() => onChange(option.value)}
              style={{
                padding: 0,
                marginTop: index === 0 ? 0 : '8px', // apply margin only on items after the first
              }}
            >
              <Badge
                variant='outline'
                color={option.color}
                style={{
                  fontWeight: 600,
                  textAlign: 'left',
                  padding: '8px',
                  width: '100%',
                }}
              >
                {option.label}
              </Badge>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default BadgeSelector;
