import React, { useState, useEffect, useRef } from 'react';
import './AudioPlayer.css';
import env from 'env';
import axios from 'api/axiosConfig';
import { Loader } from '@mantine/core';

const VideoPlayer = ({ callId, seekTimestamp, webAudio }) => {
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const [isRenderingVideo, setIsRenderingVideo] = useState(true);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (seekTimestamp !== null && videoRef.current) {
      console.log('setting play pos to seek timestamp', seekTimestamp);
      videoRef.current.currentTime = seekTimestamp;
      videoRef.current.play();
    }
  }, [seekTimestamp]);

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (videoUrl) {
      console.log('Skipping check, already have video URL:', videoUrl);
      return;
    }

    let isMounted = true; // To prevent state updates if the component is unmounted

    const checkAvailability = async () => {
      try {
        const endpoint = webAudio
          ? `/check_web_audio_recording/${callId}`
          : `/check_phone_recording/${callId}`;

        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}${endpoint}`
        );

        if (response.status === 200 && response.data.available) {
          if (isMounted) {
            // Introduce a delay before setting the videoUrl
            // Without this delay, there is a bug with ResizeObserver + the moving drawer + the <video> element
            timeoutIdRef.current = setTimeout(() => {
              setIsVideoAvailable(true);
              setVideoUrl(response.data.video_url);
              setIsLoading(false);
            }, 175);
          }
          // Stop polling if it was running
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
          }
        } else {
          throw new Error('Video not available');
        }
      } catch (error) {
        console.log('Video not available yet. Retrying...');
        // If it's the initial request and failed, start polling
        if (!pollingIntervalRef.current) {
          startPolling();
        }
      }
    };

    const startPolling = () => {
      pollingIntervalRef.current = setInterval(checkAvailability, 3000);
    };

    // Perform the initial check immediately
    checkAvailability();

    // Cleanup function
    return () => {
      isMounted = false;
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [callId, webAudio, videoUrl]);

  useEffect(() => {
    const video = videoRef.current;

    const handleLoadedData = () => {
      setIsRenderingVideo(false);
    };

    if (video) {
      video.addEventListener('loadeddata', handleLoadedData);
    }

    return () => {
      if (video) {
        video.removeEventListener('loadeddata', handleLoadedData);
      }
    };
  }, [isVideoAvailable]);

  useEffect(() => {
    const video = videoRef.current;

    // Define the handler function once
    const handleLoadedData = () => {
      setIsRenderingVideo(false);
    };

    if (video) {
      video.addEventListener('loadeddata', handleLoadedData);
    }

    return () => {
      if (video) {
        video.removeEventListener('loadeddata', handleLoadedData);
      }
    };
  }, [isVideoAvailable]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      {(isLoading || isRenderingVideo) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '60%',
            flexDirection: 'column',
            borderRadius: '16px',
            border: '1px solid var(--salv-dark-2)',
            aspectRatio: 5.5,
            // height: '300px',
            opacity: '.7',
          }}
        >
          <h4 style={{ marginBottom: '0px', marginTop: '8px' }}>
            {webAudio ? 'Loading Call Audio' : 'Loading Phone Call'}
          </h4>
          <Loader type='dots' size='sm' />
        </div>
      )}
      {isVideoAvailable && (
        <video
          controls
          ref={videoRef}
          style={{
            borderRadius: '16px',
            width: '80%',
            position: 'relative',
            height: '60px',
            marginBottom: '6px',
            display: isRenderingVideo ? 'none' : 'block',
            // border: '4px solid var(--salv-dark-1)',
          }}
        >
          <source src={videoUrl} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const PhoneCallRecordingPlayer = ({ callId, seekTimestamp, webAudio }) => {
  return (
    <div style={{ display: 'flex', fontSize: '14px' }}>
      {!!callId && (
        <VideoPlayer
          callId={callId}
          seekTimestamp={seekTimestamp}
          webAudio={webAudio}
        />
      )}
    </div>
  );
};

export default PhoneCallRecordingPlayer;
