import { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import env from 'env';
import { RequirementImportance } from 'pages/editorv2/types';
import { notifications } from '@mantine/notifications';
import { isAxiosError } from 'axios';
import { transcriptionCacheAtom } from 'pages/editorv2/atoms';

import axios from '../../src/api/axiosConfig';

interface GetTranscriptDataArgs {
  candidateId: string;
  campaignId: string;
}

export const useGetTranscriptData = ({
  candidateId,
  campaignId,
}: GetTranscriptDataArgs) => {
  const [isFetchingTranscript, setIsFetchingTranscript] = useState(false);
  const [transcriptData, setTranscriptData] = useState({
    transcript: '',
    requirementGradesList: [] as {
      question: string;
      answer: string;
      grade: string;
      importance: RequirementImportance;
      ts: number;
      title: string;
    }[],
    callId: '',
    overallGrade: 0,
    questionCompletionRate: 0,
    candidateName: '',
    lastCalled: '',
    allCalls: [],
    callComplete: false,
    emails: [],
    webCall: false,
  });
  const [transcriptError, setTranscriptError] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [transcriptionCache, setTranscriptionCache] = useAtom(
    transcriptionCacheAtom
  );

  useEffect(() => {
    if (!candidateId || !campaignId) {
      return;
    }

    // Cancel any ongoing requests before making a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const fetchTranscriptData = async () => {
      setIsFetchingTranscript(true);

      const cacheKey = `${candidateId}-${campaignId}`;

      if (transcriptionCache[cacheKey]) {
        setTranscriptData(transcriptionCache[cacheKey]);
        setTranscriptError(false);
        setIsFetchingTranscript(false);
        return;
      }

      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/call_details/${campaignId}`,
          {
            signal: controller.signal,
          }
        );

        const data = {
          transcript: response.data.transcript,
          requirementGradesList: response.data.requirement_grades_list,
          overallGrade:
            response.data.call_with_highest_completion?.overall_grade || 0,
          questionCompletionRate:
            response.data.call_with_highest_completion
              ?.question_completion_rate || 0,
          callId:
            response.data.call_with_highest_completion?.call_id || undefined,
          candidateName: response.data.candidate.fullName,
          lastCalled:
            response.data.call_with_highest_completion?.last_updated || '',
          allCalls: response.data.all_calls || [],
          callComplete:
            response.data.call_with_highest_completion?.call_status ===
            'COMPLETED',
          emails: response.data.emails || [],
          webCall: response.data.call_with_highest_completion?.web_call,
        };

        setTranscriptionCache({
          ...transcriptionCache,
          [cacheKey]: data,
        });

        setTranscriptData(data);
        setTranscriptError(false);
        setIsFetchingTranscript(false);
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.code === 'ERR_CANCELED') {
            console.log('Request was cancelled');
            return;
          }

          setIsFetchingTranscript(false);
          setTranscriptError(true);
          console.error('Error fetching transcript data:', error);
          notifications.show({
            title: 'There was an error retrieving the call details',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
      }
    };

    fetchTranscriptData();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [candidateId, campaignId, setTranscriptionCache, transcriptionCache]);

  return {
    isFetchingTranscript,
    transcriptData,
    transcriptError,
  };
};
