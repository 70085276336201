import React, { useState } from 'react';
import {
  Group,
  NumberInput,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
  Select,
  Text,
} from '@mantine/core';

import IndeedJobsField from './IndeedJobsField';

const CallerSettingsStep = ({ campaignSettings, campaignId }) => {
  const email = localStorage.getItem('email') || '';
  const orgId = localStorage.getItem('orgId') || '';
  const isInternal = email.includes('salv.ai');
  const isWebCall = campaignSettings.values.webCall;

  const [inputValue, setInputValue] = useState('');

  const keywords = campaignSettings.values.superBoostedKeywords || [];

  const addKeyword = () => {
    if (inputValue && keywords.length < 20) {
      campaignSettings.setFieldValue('superBoostedKeywords', [
        ...keywords,
        inputValue,
      ]);
      setInputValue('');
    }
  };

  const removeKeyword = (index) => {
    const updatedKeywords = keywords.filter((_, i) => i !== index);
    campaignSettings.setFieldValue('superBoostedKeywords', updatedKeywords);
  };

  const canSeeWebCall =
    email?.includes('john@salv.ai') ||
    email?.includes('mason@salv.ai') ||
    email?.includes('mswoff19@gmail.com') ||
    email?.includes('michael@salv.ai') ||
    orgId?.includes('537cc850-0be1-418c-a94e-12e5f1aa083d');

  return (
    <Stack justify='space-between' h='100%'>
      <>
        <SimpleGrid cols={1} style={{ width: '80%', minWidth: '400px' }}>
          <Stack>
            <TextInput
              label='Email for campaign results'
              placeholder='Your email'
              required
              {...campaignSettings.getInputProps('email')}
            />
            {isInternal && (
              <div>
                <IndeedJobsField
                  campaignId={campaignId}
                  campaignSettings={campaignSettings}
                />
              </div>
            )}
            {isInternal && !isWebCall && (
              <>
                <TextInput
                  label='Call From Number:'
                  {...campaignSettings.getInputProps('callFromNumber')}
                />
              </>
            )}
            <Select
              label='Voice'
              data={[
                'Lisa (American)',
                'George (American)',
                'Abby (British)',
                'William (British)',
              ]}
              placeholder='Select a voice'
              nothingFoundMessage='No matching voices'
              {...campaignSettings.getInputProps('voiceName')}
              allowDeselect={false}
            />
            {!isWebCall && (
              <Group
                style={{
                  border: '1px solid #80808066',
                  padding: '16px',
                  borderRadius: '12px',
                  marginTop: '20px',
                }}
              >
                <Switch
                  label='Auto-reschedule on voicemail'
                  checked={campaignSettings.values.rescheduleCallOnVoicemail}
                  {...campaignSettings.getInputProps(
                    'rescheduleCallOnVoicemail'
                  )}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    gap: '20px',
                  }}
                >
                  <NumberInput
                    w={80}
                    label='# of retries'
                    disabled={
                      !campaignSettings.values.rescheduleCallOnVoicemail
                    }
                    min={1}
                    max={5}
                    allowDecimal={false}
                    {...campaignSettings.getInputProps('callRetryCount')}
                  />
                  <NumberInput
                    w={100}
                    label='Call again after'
                    disabled={
                      !campaignSettings.values.rescheduleCallOnVoicemail
                    }
                    allowNegative={false}
                    rightSection='hours'
                    rightSectionWidth='max-content'
                    {...campaignSettings.getInputProps('callRetryHours')}
                  />
                </div>
              </Group>
            )}
            {isWebCall && (
              <NumberInput
                w={200}
                label='Interview Link Expiration'
                allowNegative={false}
                rightSection='days'
                rightSectionWidth='max-content'
                {...campaignSettings.getInputProps('webLinkExpirationDays')}
              />
            )}
            {isWebCall && (
              <div>
                <h5
                  style={{
                    marginTop: '20px',
                    marginBottom: '0px',
                    color: 'var(--salv-dark-9)',
                  }}
                >
                  Reminder Emails
                </h5>
                <Group
                  style={{
                    border: '1px solid #80808066',
                    padding: '16px',
                    borderRadius: '12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      gap: '20px',
                    }}
                  >
                    <NumberInput
                      w={180}
                      label='# Reminder Emails'
                      min={1}
                      max={5}
                      allowDecimal={false}
                      {...campaignSettings.getInputProps('reminderEmailCount')}
                    />
                    <NumberInput
                      w={180}
                      label='Send Reminder Every'
                      allowNegative={false}
                      rightSection='hours'
                      rightSectionWidth='max-content'
                      {...campaignSettings.getInputProps('reminderEmailHours')}
                    />
                  </div>
                </Group>
              </div>
            )}
            {canSeeWebCall && (
              <>
                <h5
                  style={{
                    marginTop: '10px',
                    marginBottom: '0px',
                    color: 'var(--salv-dark-9)',
                  }}
                >
                  Change Call Type
                </h5>
                <Switch
                  label='Web Call'
                  checked={campaignSettings.values.webCall}
                  {...campaignSettings.getInputProps('webCall')}
                />
              </>
            )}
            <div className='scroll-fade-bottom'></div>
            {/* New Boosted Transcription Keywords Section */}
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <h5
                style={{
                  color: 'var(--salv-dark-9)',
                  marginBottom: '10px',
                  marginTop: '10px',
                }}
              >
                Boosted Transcription Keywords
              </h5>
              <Text size='xs' style={{ marginBottom: '10px' }}>
                Improve transcription by providing words unique to the job that
                the AI has difficulty transcribing. Adding a word below improves
                the likelihood that word is transcribed correctly during the
                interview.
              </Text>
              <TextInput
                placeholder='Add a keyword and press Enter'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    addKeyword();
                  }
                }}
              />
              <Group mt='sm' style={{ flexWrap: 'wrap', gap: '8px' }}>
                {keywords.map((keyword, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      background: '#f1f3f5',
                      padding: '5px 14px',
                      borderRadius: '20px',
                      fontSize: '14px',
                    }}
                  >
                    {keyword}
                    <button
                      onClick={() => removeKeyword(index)}
                      style={{
                        background: 'none',
                        border: 'none',
                        marginLeft: '8px',
                        cursor: 'pointer',
                        paddingRight: '0px',
                      }}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </Group>
              <Text size='xs' color='dimmed' mt='xs'>
                {keywords.length}/20 keywords added
              </Text>
            </div>
            <div style={{ marginBottom: '24px' }}></div>
          </Stack>
        </SimpleGrid>
      </>
    </Stack>
  );
};

export default CallerSettingsStep;
