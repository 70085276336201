import React, { useState } from 'react';
import { Badge, Group, ScrollArea, Text } from '@mantine/core';
import { formatToLocalTime, formatToLocalTimeGmail } from 'utils/dateUtils';

export interface Email {
  id: string;
  subject: string;
  content: string;
  from: string;
  to: string[];
  provider: string;
  timestamp: string;
  isIncoming: boolean;
}

interface EmailHistoryProps {
  emails?: Email[];
  loading?: boolean;
  recruitingEmail?: string;
}

const CHARACTER_LIMIT = 300;

const EmailMessage: React.FC<{ email: Email; recruitingEmail?: string }> = ({
  email,
  recruitingEmail,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isIncoming = recruitingEmail
    ? email.from.toLowerCase() === recruitingEmail.toLowerCase()
    : email.isIncoming;

  const shouldShowMore = email.content.length > CHARACTER_LIMIT;
  const displayContent = isExpanded
    ? email.content
    : email.content.slice(0, CHARACTER_LIMIT) + (shouldShowMore ? '...' : '');

  return (
    <Group
      style={{
        marginTop: 12,
        alignItems: 'flex-start',
        padding: '12px',
        backgroundColor: isIncoming ? 'var(--mantine-color-blue-0)' : 'white',
        borderRadius: '8px',
        border: '1px solid var(--mantine-color-gray-3)',
      }}
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <div>
            <Text size='sm' fw={600} c={isIncoming ? 'blue' : 'dark'}>
              {isIncoming ? 'Received' : 'Sent'}
            </Text>
            <Text size='xs' c='dimmed'>
              {email.provider === 'gmail'
                ? formatToLocalTimeGmail(email.timestamp)
                : formatToLocalTime(email.timestamp)}
            </Text>
          </div>
          <Badge
            variant='light'
            color={isIncoming ? 'blue' : 'gray'}
            style={{ textTransform: 'none' }}
          >
            {isIncoming
              ? `To: ${email.to[0]}${email.to.length > 1 ? ` +${email.to.length - 1} more` : ''}`
              : `From: ${email.from}`}
          </Badge>
        </div>

        {email.subject && (
          <Text size='sm' fw={600} style={{ marginBottom: '8px' }}>
            {email.subject}
          </Text>
        )}

        <div>
          <Text
            size='sm'
            style={{
              whiteSpace: 'pre-wrap',
              color: 'var(--mantine-color-gray-7)',
            }}
          >
            {displayContent}
          </Text>

          {shouldShowMore && (
            <Badge
              size='sm'
              c='white'
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                marginTop: '4px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? 'Show Less' : 'Show More'}
            </Badge>
          )}
        </div>
      </div>
    </Group>
  );
};

const EmailHistory: React.FC<EmailHistoryProps> = ({
  emails = [],
  loading = false,
  recruitingEmail,
}) => {
  if (loading) {
    return (
      <div style={{ padding: '16px' }}>
        {[1, 2, 3].map((i) => (
          <div
            key={i}
            style={{
              marginBottom: '16px',
              padding: '12px',
              border: '1px solid var(--mantine-color-gray-3)',
              borderRadius: '8px',
            }}
          >
            <div className='animate-pulse'>
              <div
                style={{
                  height: '16px',
                  width: '25%',
                  backgroundColor: 'var(--mantine-color-gray-2)',
                  marginBottom: '8px',
                  borderRadius: '4px',
                }}
              />
              <div
                style={{
                  height: '12px',
                  width: '75%',
                  backgroundColor: 'var(--mantine-color-gray-2)',
                  marginBottom: '8px',
                  borderRadius: '4px',
                }}
              />
              <div
                style={{
                  height: '12px',
                  width: '50%',
                  backgroundColor: 'var(--mantine-color-gray-2)',
                  borderRadius: '4px',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!emails.length) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: '20px',
        }}
      >
        <Text c='dimmed' size='sm'>
          No email history available
        </Text>
      </div>
    );
  }

  return (
    <div className='transcript-column'>
      <ScrollArea className='scrollable-content'>
        <div className='scroll-fade-top' />
        <div className='inside' style={{ padding: '16px' }}>
          {emails
            .sort(
              (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
            .map((email) => (
              <EmailMessage
                key={email.id}
                email={email}
                recruitingEmail={recruitingEmail}
              />
            ))}
        </div>
        <div className='scroll-fade-bottom' />
      </ScrollArea>
    </div>
  );
};

export default EmailHistory;
